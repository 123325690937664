import React from "react"
import Layout from "../components/Layout/Layout"
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import Kontakt from "../components/Layout/Contact";
import CarouselReference from "../components/Components/CarouselReference";

import Pocitac from "../assets/custom-style/images/icons/computer.webp"
import Cloud from "../assets/custom-style/images/icons/cloud.webp"
import Person from "../assets/custom-style/images/icons/person.webp"

export default function Home() {
    return (
        <Layout>
            <div className="homepage">
                <div className="headerBluePic">
                    <div className="headerBlue_content">
                        <div className="container">
                            <div className="pt-2 pt-md-5 pb-2">
                                <h1 className={"text-center text-md-start text-white"}>Nabízíme&nbsp;
                                    <br className={"d-md-none"}/>
                                    jednoduché řešení<br/>
                                    pro vaše firemní IT</h1>
                            </div>
                            <div className="picCards mt-md-5">
                                <div className="row">
                                    <div className="col-12 col-md-4 mb-3">
                                        <Link to={'/spravait'} href="#" className="boxLink">
                                            <div className="card grow h-100 text-center p-0 pt-md-2 pb-md-2">
                                                <div className="card-body mb-2 mb-md-3">
                                                    <div className="card-pic mx-auto">
                                                        <div className={"d-none d-lg-block"}>
                                                            <StaticImage imgClassName={"spravait"}
                                                                         src="../assets/custom-style/images/ilustrace-01.svg"
                                                                         alt="Služba - Správa IT"
                                                            />
                                                        </div>
                                                        <div className={"d-lg-none"}>
                                                            <div className="sm-img-container">
                                                                <img className={"sm-img img-responsive"}
                                                                     src={Pocitac}
                                                                     alt="Služba - Správa IT"
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <h4 className="card-title">Správa IT</h4>
                                                    <h5 className="card-subtitle">Jsme vaše externí IT oddělení</h5>
                                                    <p className="card-text d-none d-sm-block pb-2">Staráme se, aby vaše
                                                        IT
                                                        bylo
                                                        srozumitelné, spolehlivě fungovalo a pomáhalo vám se zpracováním
                                                        a sdílením informací, v komunikaci, spolupráci a dalších
                                                        klíčových oblastech.</p>
                                                </div>
                                                <div className={'box-link-down'}>
                                                    Chci vědět víc&nbsp;›
                                                </div>
                                            </div>
                                        </Link>

                                    </div>

                                    <div className="col-12 col-md-4 mb-3">
                                        <div className="card grow h-100 text-center p-0 pt-md-2 pb-md-2">
                                            <Link to={'/microsoft365'} href="#" className="boxLink">
                                                <div className="card-body  mb-2 mb-md-3">
                                                    <div className="card-pic mx-auto">
                                                        <div className={"d-none d-lg-block"}>
                                                            <StaticImage imgClassName={"cloudovesluzby"}
                                                                         src="../assets/custom-style/images/ilustrace-02.svg"
                                                                         alt="Služba - Microsoft 365"
                                                            />
                                                        </div>
                                                        <div className={"d-lg-none"}>
                                                            <div className="sm-img-container">
                                                                <img className={"sm-img"}
                                                                     src={Cloud}
                                                                     alt="Služba - Microsoft 365"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h4 className="card-title">Microsoft 365</h4>
                                                    <h5 className="card-subtitle">Jsme váš Microsoft partner</h5>
                                                    <p className="card-text d-none d-sm-block pb-2">Pomůžeme vám s
                                                        úspěšnou
                                                        migrací do
                                                        prostředí Microsoft 365. Staráme se, aby ve vašem Microsoft
                                                        Cloudu vše běželo bezpečně a bez problémů na všech vašich
                                                        zařízeních.</p>
                                                </div>
                                                <div className={'box-link-down'}>
                                                    Chci vědět víc&nbsp;›
                                                </div>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-4 mb-3">
                                        <Link to={'/heliosinuvio'} href="#" className="boxLink">
                                            <div className="card grow h-100 text-center p-0 pt-md-2 pb-md-2">
                                                <div className="card-body mb-2 mb-md-3">
                                                    <div className="card-pic mx-auto">

                                                        <div className={"d-none d-lg-block"}>
                                                            <StaticImage imgClassName={"itmanazer"}
                                                                         src="../assets/custom-style/images/ilustrace-03.svg"
                                                                         alt="Služba - IT manažer"/>

                                                        </div>
                                                        <div className={"d-lg-none"}>
                                                            <div className="sm-img-container">
                                                                <img className={"sm-img"}
                                                                     src={Person}
                                                                     alt="Služba - IT manažer"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <h4 className="card-title">Helios iNuvio</h4>
                                                    <h5 className="card-subtitle">Nasadíme Vám to nejlepší ERP</h5>
                                                    <p className="card-text d-none d-sm-block pb-2">Díky ERP Helios
                                                        iNuvio zefektivníte správu dat a řízení procesů. Pomůžeme vám
                                                        zlepšit produktivita a konkurenceschopnost díky přizpůsobitelným
                                                        funkcím systému a intuitivnímu ovládání.</p>

                                                </div>
                                                <div className={'box-link-down'}>
                                                    Chci vědět víc&nbsp;›
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container text-center text-md-start pt-md-3 pt-5">
                    <div className="row">
                        <div className="col-md">
                            <h2 className=" heading-border-bottom-blue mb-5">Kdo jsme</h2>
                            <p>Digi Office je Pražská <strong>IT firma s 15 letou historií</strong> a praktickými
                                zkušenostmi. Děláme IT a technologie pro malé a středně velké firmy a organizace.</p>

                            <p>Přinášíme Vám řešení pro práci s informacemi, sdílení, komunikaci a spolupráci, navíc vše
                                zabezpečíme a nastavíme tak, abyste ho mohli využívat v práci, doma i na cestách.</p>

                            <p><strong>Dokážeme se o Vás postarat komplexně formou Outsourcingu</strong>, tedy převzetím
                                správy celého Vašeho IT.</p>
                            <Link to={"/onas"}>
                                <button type="button"
                                        className="d-none d-md-inline-block btn button-rounded-blue rounded-pill mt-4">
                                    O nás&nbsp;›
                                </button>
                            </Link>
                        </div>

                        <div className="col-md text-center mb-4 pt-md-5 pt-xl-0 mt-md-5 mt-xl-0">
                            <StaticImage imgClassName={"aboutus"} className="w-75"
                                         alt="DigiOffice tým"
                                         width={800}
                                         src="../assets/custom-style/images/digi/_NG_8377_ACR.jpg"/>
                        </div>
                    </div>

                    <div className="px-3 px-md-0">
                        <CarouselReference/>
                    </div>


                </div>


                {/* <div className="bgGrey">
                    <div className="container">
                        <h2 className="heading-border-bottom-blue pt-5 mb-3">Z našeho blogu</h2>
                        <div className="row">
                            <div className="col">
                                <div className="card h-100">
                                    <StaticImage className="card-img-top w-100" src="../assets/custom-style/images/blogimg.jpg" />

                                    <div class="card-body">
                                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                        </div>


                        <button type="button" className="btn button-rounded-blue rounded-pill mt-4 mb-4">Všechny články&nbsp;›</button>

                    </div>
                </div> */}


            </div>
            <Kontakt/>
        </Layout>
    );
}